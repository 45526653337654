import Sidenav from '../components/Sidenav'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { useState } from 'react'
import { useFetch } from '../hooks/useFetch'
import { useNavigate } from 'react-router-dom'
import { base_api } from '../components/Utils'

export default function Create() {
    const [refill, setRefill] = useState(false)
    const initInput = { received: '', opening: '', closing: '', newOpen: undefined, newClose: undefined }
    const [input, setInput] = useState(initInput)
    const { postData, redirect } = useFetch(`${base_api}/create/record`, 'POST')
    const navigate = useNavigate()

    const handleRefill = () => {
        setRefill(!refill)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setInput(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        postData(input)
        setInput(initInput)
        redirect && navigate('/')
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Sidenav />
                    <div className="col-lg-10 create">
                        <div className="container mt-5">
                            <div className="page-heading">
                                <h4 className="fw-bold">
                                    Staff Input
                                </h4>
                                <p className="fw-bold">
                                    {new Date().toLocaleDateString('default',
                                        { month: 'long', day: 'numeric', year: 'numeric' })
                                    }
                                </p>
                            </div>
                            <div className="main_content my-5 pb-5">
                                <p className="text-danger fw-bold">*Please note all fields are required</p>
                                <div className="col-lg-4">
                                    <form onSubmit={(e) => handleSubmit(e)}>
                                        <div className='my-3'>
                                            <label>
                                                <span className='fw-bold mb-2 d-block'>Total Amount Received Today (in Naira)</span>
                                                <input
                                                    type="number"
                                                    className='form-control'
                                                    name='received'
                                                    value={input.received}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </label>
                                        </div>
                                        <div className="my-3">
                                            <label>
                                                <span className="fw-bold mb-2 d-block">Opening Stock</span>
                                                <div className="d-flex">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name='opening'
                                                        value={input.opening}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <span className="text-primary d-block mt-2 ms-2 fw-bold">KG</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="my-3">
                                            <label>
                                                <span className="fw-bold mb-2 d-block">Closing Stock</span>
                                                <div className="d-flex">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name='closing'
                                                        value={input.closing}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <span className="text-primary d-block mt-2 ms-2 fw-bold">KG</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="my-3 margin-auto">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name='refill'
                                                    checked={refill}
                                                    onClick={handleRefill}
                                                    readOnly
                                                />
                                                <span className='ms-2 fw-bold'>Refill</span>
                                            </label>
                                        </div>
                                        {refill && <>
                                            <div className="my-3">
                                                <label>
                                                    <span className="fw-bold mb-2 d-block">New Opening Stock</span>
                                                    <div className="d-flex">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name='newOpen'
                                                            value={input.newOpen}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                        <span className="text-primary d-block mt-2 ms-2 fw-bold">KG</span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="my-3">
                                                <label>
                                                    <span className="fw-bold mb-2 d-block">New Closing Stock</span>
                                                    <div className="d-flex">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name='newClose'
                                                            value={input.newClose}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                        <span className="text-primary d-block mt-2 ms-2 fw-bold">KG</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </>}
                                        <div className='btn-margin mt-5'>
                                            <button className="btn btn-primary bg-primary">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
