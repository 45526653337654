import Sidenav from "../components/Sidenav"
import Sales from "./page_components/Sales"


export default function SalesPage() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Sidenav />
                    <Sales />
                </div>
            </div>
        </>
    )
}