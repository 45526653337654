import Sidenav from "../components/Sidenav"
import Overview from "./page_components/Overview"


export default function Home() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Sidenav />
                    <Overview />
                </div>
            </div>
        </>
    )
}