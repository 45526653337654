import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom'
import Home from './pages/Home'
import RevenuePage from './pages/RevenuePage';
import SalesPage from './pages/SalesPage';
import RevenueDetails from './pages/RevenueDetails';
import SalesDetails from './pages/SalesDetails';
import Create from './pages/Create';
import { useState } from 'react';

function App() {
  const [admin, setAdmin] = useState(false)
  const [error, setError] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault()
    if (email === 'dash-admin@allescharis.ng' && password === 'dash-admin12345678') {
      setAdmin(true)
      navigate('/')
    } else {
      setError('Invalid login credentials')
    }
  }

  return (
    <>
      {!admin ? <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 border mt-5 bg-white mx-auto">
              <div className="row">
                <div className="col-lg-6 mx-auto">
                  <div className="container mt-5">
                    <div className="page-heading text-center">
                      <h4 className="fw-bold">
                        Admin Login
                      </h4>
                      <p>Please login to continue</p>
                    </div>
                    <div className="main_content my-5 pb-5">
                      {error && <p className="text-danger text-center">{error}</p>}
                      <div className="col-lg-4">
                        <form onSubmit={(e) => handleSubmit(e)}>
                          <div className='my-3'>
                            <label className='fw-bold mb-2 d-block'>
                              <span className="d-block">Email</span>
                              <input
                                type="email"
                                className='form-control'
                                name='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                autoComplete='email'
                              />
                            </label>
                          </div>
                          <div className="my-3">
                            <label className="fw-bold mb-2 d-block">
                              <span className="d-block">Password</span>
                              <input
                                type="password"
                                className="form-control"
                                name='password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                autoComplete='current-password'
                              />
                            </label>
                          </div>
                          <div>
                            <button className="btn btn-primary bg-primary">Submit</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </> :
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/revenue' element={<RevenuePage />} />
          <Route path='/revenue/details' element={<RevenueDetails />} />
          <Route path='/sales' element={<SalesPage />} />
          <Route path='/sales/details' element={<SalesDetails />} />
          <Route path='/create' element={<Create />} />
        </Routes>
      }
    </>
  );
}

export default App;
