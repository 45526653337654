import { Chart, registerables } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useFetch } from '../hooks/useFetch'
import {base_api} from './Utils'

Chart.register(...registerables)

export default function BarChart({ url }) {
    const { data, error, pending } = useFetch(`${base_api}/${url}`)
    const values = {
        labels: data && data.labels,
        datasets: [{
            data: data && data.values,
            backgroundColor: "#3376C3"
        }]
    }
    const options = {
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                display: false
            }
        }
    }

    return (
        <>
            {pending ? 'Loading Data...' : <>
                {error ? error : <Bar data={values} options={options} height={125} />}
            </>}
        </>
    )
}