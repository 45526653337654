import Sidenav from "../components/Sidenav"
import GraphDetails from "./page_components/GraphDetails"


export default function RevenueDetails() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Sidenav />
                    <GraphDetails title={'sales'} />
                </div>
            </div>
        </>
    )
}