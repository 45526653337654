import Sidenav from "../components/Sidenav"
import Revenue from "./page_components/Revenue"


export default function RevenuePage() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Sidenav />
                    <Revenue />
                </div>
            </div>
        </>
    )
}