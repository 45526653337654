import 'bootstrap-icons/font/bootstrap-icons.css'
import { Link } from 'react-router-dom'
import Charts from '../../components/Charts'
import Stats from '../../components/Stats'
import { date } from '../../components/Utils'

export default function Overview() {
    return (
        <>
            <div className="col-lg-10 overview">
                <div className="container mt-5">
                    <div className="page-heading">
                        <h4 className="fw-bold">Overview</h4>
                        <p className="fw-bold">
                            {new Date().toLocaleDateString('default',
                                { month: 'long', day: 'numeric', year: 'numeric' })
                            }
                        </p>
                    </div>
                    <div className="main_content mt-5">
                        <div className="revenue my-4">
                            <div className="heading mb-4">
                                <Link to={'/revenue'} className='d-flex'>
                                    <h3 className='text-uppercase text-primary fw-600'>REVENUE</h3>
                                    <small className='d-block text-primary'><i className="bi bi-arrow-right-circle-fill ms-2"></i>
                                    </small>
                                </Link>
                            </div>
                            <div className="stats my-4">
                                <div className="row">
                                    <Stats title={'Total Revenue Yesterday'} url={`revenue/day/${date.year}/${date.month}/${date.day}`} />
                                    <Stats title={'Total Revenue In Previous Week'} url={`revenue/week/${date.year}/${date.week}`} />
                                    <Stats title={'Total Revenue in Previous Month'} url={`revenue/month/${date.year}/${date.prevMonth}`} />
                                </div>
                            </div>
                            <Charts
                                pieTitle={'Previous Week Revenue Chart'}
                                barTitle={'Previous Week Revenue Graph'}
                                url={`states/revenue/week/${date.year}/${date.week}`}                 
                            />
                        </div>
                        <div className="sales my-5">
                            <div className="heading mb-4">
                                <Link to={'/sales'} className='d-flex'>
                                    <h3 className='text-uppercase text-primary fw-600'>Sales</h3>
                                    <small className='d-block text-primary'><i className="bi bi-arrow-right-circle-fill ms-2"></i>
                                    </small>
                                </Link>
                            </div>
                            <div className="stats my-4">
                                <div className="row">
                                    <Stats title={'Total Sales Yesterday'} url={`sales/day/${date.year}/${date.month}/${date.day}`} sales={true} />
                                    <Stats title={'Total Sales In Previous Week'} url={`sales/week/${date.year}/${date.week}`} sales={true} />
                                    <Stats title={'Total Sales in Previous Month'} url={`sales/month/${date.year}/${date.prevMonth}`} sales={true} />
                                </div>
                            </div>
                            <Charts
                                pieTitle={'Previous Week Sales Chart'}
                                barTitle={'Previous Week Sales Graph'}
                                url={`states/sales/week/${date.year}/${date.week}`}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
