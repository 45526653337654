import 'bootstrap-icons/font/bootstrap-icons.css'
import React, { useState } from 'react'
import Charts from '../../components/Charts'
import Stats from '../../components/Stats'
import { date, base_api } from '../../components/Utils'
import { useFetch } from '../../hooks/useFetch'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

export default function Revenue() {
  const { data: states } = useFetch(`${base_api}/states`)
  const [selectedDate, setSelectedDate] = useState(date)
  const [startDate, setStartDate] = useState(new Date());
  const [timeFrame, setTimeFrame] = useState({
    day: 'Yesterday',
    week: 'Previous Week',
    month: 'Previous Month'
  })
  const Calendar = React.forwardRef(({ onClick }, ref) => (
    <img src='/calendar.png' className='ms-2' onClick={onClick} ref={ref} alt='calendar-icon' />
  ));
  const allStations = {
    state: 'All Stations',
    dayURL: `stations/revenue/day/${selectedDate.year}/${selectedDate.month}/${selectedDate.day}`,
    monthURL: `stations/revenue/month/${selectedDate.year}/${selectedDate.prevMonth}`,
    weekURL: `stations/revenue/week/${selectedDate.year}/${selectedDate.week}`
  }

  const [per, setPer] = useState('State')
  const [station, setStation] = useState(allStations)
  const handleStationChange = (e) => {
    if (e === 'All Stations') {
      setStation(allStations)
    } else {
      setStation({
        state: e,
        dayURL: `stations/revenue/day/${e}/${selectedDate.year}/${selectedDate.month}/${selectedDate.day}`,
        monthURL: `stations/revenue/month/${e}/${selectedDate.year}/${selectedDate.prevMonth}`,
        weekURL: `stations/revenue/week/${e}/${selectedDate.year}/${selectedDate.week}`
      })
    }
  }

  const handleDateChange = (date) => {
    const currentDate = new Date(date)
    const yearStart = new Date(currentDate.getFullYear(), 0, 1)
    const newDate = {
      day: currentDate.getDate(),
      thisWeek: Math.round(Math.ceil(((currentDate - yearStart) / 86400000) + 1) / 7),
      week: Math.round(Math.ceil(((currentDate - yearStart) / 86400000) + 1) / 7) - 1,
      month: currentDate.toLocaleDateString('default', { month: 'long' }).toLocaleLowerCase(),
      year: currentDate.getFullYear()
    }
    setStartDate(date)
    setSelectedDate(newDate)
    setTimeFrame({
      day: "Today",
      week: "This Week",
      month: "This Month"
    })
  }

  return (
    <>
      <div className="col-lg-10 overview">
        <div className="container mt-5">
          <div className="page-heading">
            <h4 className="fw-600 text-primary">REVENUE - Per {per}</h4>
            <div className="d-flex">
              <p className="fw-bold text-capitalize">
                {selectedDate.month} {selectedDate.day}, {selectedDate.year}
              </p>
              <div>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => handleDateChange(date)}
                  customInput={<Calendar />}
                />
              </div>
            </div>
          </div>
          <div className="main_content mt-5">
            <div className="stats my-4">
              <div className="row">
                <Stats title={'Total Revenue ' + timeFrame.day} url={`revenue/day/${selectedDate.year}/${selectedDate.month}/${selectedDate.day}`} />
                <Stats title={'Total Revenue in ' + timeFrame.week} url={`revenue/week/${selectedDate.year}/${selectedDate.week}`} />
                <Stats title={'Total Revenue in ' + timeFrame.month} url={`revenue/month/${selectedDate.year}/${selectedDate.prevMonth}`} />
              </div>
            </div>
            <div className="tabs my-4">
              <ul className="nav nav-tabs bg-primary p-3 rounded mx-auto my-5" role="tablist">
                <li
                  className="nav-item" role="presentation"
                  onClick={() => setPer('State')}
                >
                  <button className="nav-link active text-uppercase fw-600 rounded" data-bs-toggle="tab" data-bs-target="#perState" type="button" role="tab" aria-controls="perState" aria-selected="true">View By State</button>
                </li>
                <li
                  className="nav-item" role="presentation"
                  onClick={() => setPer('Station')}                >
                  <button className="nav-link text-uppercase ms-1 fw-600 rounded" data-bs-toggle="tab" data-bs-target="#perStation" type="button" role="tab" aria-controls="perStation" aria-selected="false">View By Station</button>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane fade show active" id="perState" role="tabpanel" tabIndex="0">
                  <Charts
                    barTitle={timeFrame.day + "'s Revenue Graph (Per State)"}
                    pieTitle={timeFrame.day + "'s Revenue Chart"}
                    more={true}
                    reverse={true}
                    revenue={true}
                    url={`states/revenue/day/${selectedDate.year}/${selectedDate.month}/${selectedDate.day}`}
                  />
                  <Charts
                    barTitle={timeFrame.week + "'s Revenue Graph (Per State)"}
                    pieTitle={timeFrame.week + "'s Revenue Chart"}
                    more={true}
                    reverse={true}
                    revenue={true}
                    url={`states/revenue/week/${selectedDate.year}/${selectedDate.week}`}
                  />
                  <Charts
                    barTitle={timeFrame.month + "'s Revenue Graph (Per State)"}
                    pieTitle={timeFrame.month + "'s Revenue Chart"}
                    more={true}
                    reverse={true}
                    revenue={true}
                    url={`states/revenue/month/${selectedDate.year}/${selectedDate.prevMonth}`}
                  />
                </div>
                <div className="tab-pane fade" id="perStation" role="tabpanel" tabIndex="0">
                  <div className="selector my-4 text-center mx-auto d-flex">
                    <span className='d-block w-50 pt-2'>Select State to View -</span>
                    <select onChange={(e) => handleStationChange(e.target.value)} name="" id="" className='form-select w-50 ms-2'>
                      <option value="All Stations">All Stations</option>
                      {states && states.states.map((state) => (
                        <option key={state} value={state}>{state}</option>
                      ))}
                    </select>
                  </div>
                  <Charts
                    barTitle={`${timeFrame.day}'s Revenue Graph (Per Station) in ${station.state}`}
                    pieTitle={`${timeFrame.day}'s Revenue Chart in ${station.state}`}
                    more={true}
                    reverse={true}
                    revenue={true}
                    url={station.dayURL}
                  />
                  <Charts
                    barTitle={`${timeFrame.week}'s Revenue Graph (Per Station) in ${station.state}`}
                    pieTitle={`${timeFrame.week}'s Revenue Chart in ${station.state}`}
                    more={true}
                    reverse={true}
                    revenue={true}
                    url={station.weekURL}
                  />
                  <Charts
                    barTitle={`${timeFrame.month}'s Revenue Graph (Per Station) in ${station.state}`}
                    pieTitle={`${timeFrame.month}'s Revenue Chart in ${station.state}`}
                    more={true}
                    reverse={true}
                    revenue={true}
                    url={station.monthURL}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
