const currentDate = new Date()
const yearStart = new Date(currentDate.getFullYear(), 0, 1)
const date = {
    day: currentDate.getDate(),
    prevDay: currentDate.getDate() - 1,
    thisWeek: Math.round(Math.ceil(((currentDate - yearStart) / 86400000) + 1) / 7),
    week: Math.round(Math.ceil(((currentDate - yearStart) / 86400000) + 1) / 7) - 1,
    prevMonth: new Date(currentDate.getFullYear(), currentDate.getMonth() - 1).toLocaleDateString('default', { month: 'long' }).toLocaleLowerCase(),
    month: currentDate.toLocaleDateString('default', { month: 'long' }).toLocaleLowerCase(),
    year: currentDate.getFullYear()
}

const base_api = 'https://api.allescharis.ng'

export { date, base_api }