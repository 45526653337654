import React, { useState } from "react"
import Stats from "../../components/Stats"
import { date, base_api } from "../../components/Utils"
import { useFetch } from "../../hooks/useFetch"
import { Link } from "react-router-dom"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

export default function GraphDetails({ title }) {
    const [selectedDate, setSelectedDate] = useState(date)
    const [startDate, setStartDate] = useState(new Date());
    const [timeFrame, setTimeFrame] = useState({
        day: 'Yesterday',
        week: 'Previous Week',
        month: 'Previous Month'
    })
    const Calendar = React.forwardRef(({ onClick }, ref) => (
        <img src='/calendar.png' className='ms-2' onClick={onClick} ref={ref} alt='calendar-icon' />
    ));

    const state = {
        title: 'State',
        query: {
            url: `states/details/${title}/month/${selectedDate.year}/${selectedDate.prevMonth}`,
            title: 'Last Month'
        }
    }
    const station = {
        title: 'Station',
        state: 'All Stations',
        query: {
            url: `stations/details/${title}/month/${selectedDate.year}/${selectedDate.prevMonth}`,
            title: 'Last Month'
        }
    }
    const stats = {
        day: `${title}/day/${selectedDate.year}/${selectedDate.month}/${selectedDate.day}`,
        week: `${title}/week/${selectedDate.year}/${selectedDate.week}`,
        month: `${title}/month/${selectedDate.year}/${selectedDate.prevMonth}`,
    }
    const [per, setPer] = useState(state)
    const [changeState, setChangeState] = useState('All Stations')
    const { data: states } = useFetch(`${base_api}/states`)
    const { data, error, pending } = useFetch(`${base_api}/${per.query.url}`)
    const [url, setUrl] = useState(stats)

    const handlePerChange = () => {
        setPer(station)
    }

    const changeQueryUrl = (e) => {
        switch (e) {
            case 'Today':
                setPer({
                    title: 'State',
                    query: {
                        title: e,
                        url: `states/details/${title}/day/${selectedDate.year}/${selectedDate.month}/${selectedDate.day}`
                    }
                })
                break;
            case 'Yesterday':
                setPer({
                    title: 'State',
                    query: {
                        title: e,
                        url: `states/details/${title}/day/${selectedDate.year}/${selectedDate.month}/${selectedDate.prevDay}`
                    }
                })
                break;
            case 'Last Week':
                setPer({
                    title: 'State',
                    query: {
                        title: e,
                        url: `states/details/${title}/week/${selectedDate.year}/${selectedDate.week}`
                    }
                })
                break;
            case 'This Week':
                setPer({
                    title: 'State',
                    query: {
                        title: e,
                        url: `states/details/${title}/week/${selectedDate.year}/${selectedDate.week}`
                    }
                })
                break;            
            case 'This Month':
                setPer({
                    title: 'State',
                    query: {
                        title: e,
                        url: `states/details/${title}/month/${selectedDate.year}/${selectedDate.month}`
                    }
                })
                break;            
            default:
                setPer(state)
                break;
        }
    }

    const changeStationQueryUrl = (e) => {
        if (changeState === 'All Stations') {
            switch (e) {
                case 'Today':
                    setPer({
                        title: 'Station',
                        state: changeState,
                        query: {
                            title: e,
                            url: `stations/details/${title}/day/${selectedDate.year}/${selectedDate.month}/${new Date().getDate()}`
                        }
                    })
                    break;
                case 'Yesterday':
                    setPer({
                        title: 'Station',
                        state: changeState,
                        query: {
                            title: e,
                            url: `stations/details/${title}/day/${selectedDate.year}/${selectedDate.month}/${selectedDate.day}`
                        }
                    })
                    break;
                case 'Last Week':
                    setPer({
                        title: 'Station',
                        state: changeState,
                        query: {
                            title: e,
                            url: `stations/details/${title}/week/${selectedDate.year}/${selectedDate.week}`
                        }
                    })
                    break;
                default:
                    setPer({
                        title: 'Station',
                        state: changeState,
                        query: {
                            url: `stations/details/${title}/month/${selectedDate.year}/${selectedDate.prevMonth}`,
                            title: 'Last Month'
                        }
                    })
                    break;
            }
        } else {
            switch (e) {
                case 'Today':
                    setPer({
                        title: 'Station',
                        state: changeState,
                        query: {
                            title: e,
                            url: `stations/details/${title}/day/${changeState}/${selectedDate.year}/${selectedDate.month}/${new Date().getDate()}`
                        }
                    })
                    break;
                case 'Yesterday':
                    setPer({
                        title: 'Station',
                        state: changeState,
                        query: {
                            title: e,
                            url: `stations/details/${title}/day/${changeState}/${selectedDate.year}/${selectedDate.month}/${selectedDate.day}`
                        }
                    })
                    break;
                case 'Last Week':
                    setPer({
                        title: 'Station',
                        state: changeState,
                        query: {
                            title: e,
                            url: `stations/details/${title}/week/${changeState}/${selectedDate.year}/${selectedDate.week}`
                        }
                    })
                    break;
                case 'This Week':
                    setPer({
                        title: 'Station',
                        state: changeState,
                        query: {
                            title: e,
                            url: `stations/details/${title}/week/${changeState}/${selectedDate.year}/${selectedDate.week}`
                        }
                    })
                    break;
                case 'This Month':
                    setPer({
                        title: 'Station',
                        state: changeState,
                        query: {
                            title: e,
                            url: `stations/details/${title}/month/${changeState}/${selectedDate.year}/${selectedDate.month}`
                        }
                    })
                    break;
                default:
                    setPer({
                        title: 'Station',
                        state: changeState,
                        query: {
                            url: `stations/details/${title}/month/${changeState}/${selectedDate.year}/${selectedDate.prevMonth}`,
                            title: 'Last Month'
                        }
                    })
                    break;
            }
        }
    }

    const changeStation = (e) => {
        if (e === 'All Stations') {
            setChangeState('All Stations')
            setPer(station)
            setUrl(stats)
        } else {
            setChangeState(e)
            setPer({
                title: 'Station',
                state: e,
                query: {
                    url: `stations/details/${title}/month/${e}/${selectedDate.year}/${selectedDate.prevMonth}`,
                    title: 'Last Month'
                }
            })
            setUrl({
                day: `${title}/day/${e}/${selectedDate.year}/${selectedDate.month}/${new Date().getDate()}`,
                week: `${title}/week/${e}/${selectedDate.year}/${selectedDate.week}`,
                month: `${title}/month/${e}/${selectedDate.year}/${selectedDate.prevMonth}`
            })
        }
    }

    const handleDateChange = (date) => {
        const currentDate = new Date(date)
        const yearStart = new Date(currentDate.getFullYear(), 0, 1)
        const newDate = {
            day: currentDate.getDate(),
            prevDay: currentDate.getDate() - 1,
            week: Math.round(Math.ceil(((currentDate - yearStart) / 86400000) + 1) / 7),
            month: currentDate.toLocaleDateString('default', { month: 'long' }).toLocaleLowerCase(),
            year: currentDate.getFullYear()
        }
        setStartDate(date)
        setSelectedDate(newDate)
        if (per.title === 'State') {
            setPer({
                title: 'State',
                query: {
                    title: 'This Month',
                    url: `states/details/${title}/month/${newDate.year}/${newDate.month}`
                }
            })
            setUrl({
                day: `${title}/day/${newDate.year}/${newDate.month}/${newDate.day}`,
                week: `${title}/week/${newDate.year}/${newDate.week}`,
                month: `${title}/month/${newDate.year}/${newDate.month}`
            })
        } else {
            setPer({
                title: 'Station',
                state: changeState,
                query: {
                    url: `stations/details/${title}/month/${changeState}/${newDate.year}/${newDate.month}`,
                    title: 'This Month'
                }
            })
            if (changeState === 'All Stations') {
                setUrl({
                    day: `${title}/day/${newDate.year}/${newDate.month}/${newDate.day}`,
                    week: `${title}/week/${newDate.year}/${newDate.week}`,
                    month: `${title}/month/${newDate.year}/${newDate.month}`
                })
            } else {
                setUrl({
                    day: `${title}/day/${changeState}/${newDate.year}/${newDate.month}/${newDate.day}`,
                    week: `${title}/week/${changeState}/${newDate.year}/${newDate.week}`,
                    month: `${title}/month/${changeState}/${newDate.year}/${newDate.month}`
                })
            }
        }
        setTimeFrame({
            day: 'Today',
            week: 'This Week',
            month: 'This Month'
        })
    }

    return (
        <>
            <div className="col-lg-10 overview">
                <div className="container mt-5">
                    <div className="page-heading">
                        <h4 className="fw-600 text-primary"><span className="text-uppercase">{title}</span> - Per {per.title}</h4>
                        <div className="d-flex">
                            <p className="fw-bold text-capitalize">
                                {selectedDate.month} {selectedDate.day}, {selectedDate.year}
                            </p>
                            <div>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => handleDateChange(date)}
                                    customInput={<Calendar />}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="main_content mt-5">
                        <div className="stats my-4">
                            <div className="row">
                                <Stats title={`Total ${title} ${timeFrame.day}`} url={url.day} sales={title === 'sales'} />
                                <Stats title={`Total ${title} In ${timeFrame.week}`} url={url.week} sales={title === 'sales'} />
                                <Stats title={`Total ${title} In ${timeFrame.month}`} url={url.month} sales={title === 'sales'} />
                            </div>
                        </div>
                        <div className="tabs my-4">
                            <ul className="nav nav-tabs bg-primary p-3 rounded mx-auto my-5" role="tablist">
                                <li
                                    className="nav-item" role="presentation"
                                    onClick={() => setPer(state)}
                                >
                                    <button className="nav-link active text-uppercase fw-600 rounded" data-bs-toggle="tab" data-bs-target="#perState" type="button" role="tab" aria-controls="perState" aria-selected="true">View By State</button>
                                </li>
                                <li
                                    className="nav-item" role="presentation"
                                    onClick={handlePerChange}
                                >
                                    <button className="nav-link text-uppercase ms-1 fw-600 rounded" data-bs-toggle="tab" data-bs-target="#perStation" type="button" role="tab" aria-controls="perStation" aria-selected="false">View By Station</button>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="perState" role="tabpanel" tabIndex="0">
                                    <div style={{ width: '55%' }}>
                                        <h6 className='text-primary mb-5 text-capitalize'>{per.query.title}'s {title} (Per State)</h6>
                                        <table className='table mb-5 border-black'>
                                            <thead className='text-primary'>
                                                <tr>
                                                    <th scope="col">State</th>
                                                    <th scope="col" className='text-end text-capitalize'>{title}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pending ? <tr><td>Loading Data...</td></tr> : <>
                                                    {error ? <tr><td>{error}</td></tr> : <>
                                                        {data && data.map(({ _id, value }) => (
                                                            <tr key={_id}>
                                                                <td>{_id}</td>
                                                                <td className='text-end'>
                                                                    {title === 'revenue' &&
                                                                        <>
                                                                            <span className="naira">N</span>&nbsp;
                                                                            {value.toLocaleString()}
                                                                        </>
                                                                    }
                                                                    {title !== 'revenue' &&
                                                                        <>
                                                                            {value.toLocaleString() + ' KG'}
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>}
                                                </>}
                                            </tbody>
                                        </table>
                                        <div className="related-links mb-5">
                                            <Link to='#!' className="text-primary" onClick={() => changeQueryUrl('Today')}>
                                                <p className="d-flex">View Today's {title} (Per State) <small className="d-block ms-2"><i className="bi bi-arrow-right-circle-fill"></i></small></p>
                                            </Link>
                                            <Link to='#!' className="text-primary" onClick={() => changeQueryUrl('Yesterday')}>
                                                <p className="d-flex">View Yesterday's {title} (Per State) <small className="d-block ms-2"><i className="bi bi-arrow-right-circle-fill"></i></small></p>
                                            </Link>
                                            <Link to='#!' className="text-primary" onClick={() => changeQueryUrl(timeFrame.week)}>
                                                <p className="d-flex text-capitalize">View {timeFrame.week}'s {title} (Per State) <small className="d-block ms-2"><i className="bi bi-arrow-right-circle-fill"></i></small></p>
                                            </Link>
                                            <Link to='#!' className="text-primary" onClick={() => changeQueryUrl(timeFrame.month)}>
                                                <p className="d-flex text-capitalize">View {timeFrame.month}'s {title} (Per State) <small className="d-block ms-2"><i className="bi bi-arrow-right-circle-fill"></i></small></p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="perStation" role="tabpanel" tabIndex="0">
                                    <div className="selector my-4 text-center mx-auto d-flex">
                                        <span className='d-block w-50 pt-2'>Select State to View -</span>
                                        <select onChange={(e) => changeStation(e.target.value)} name="" id="" className='form-select w-50 ms-2'>
                                            <option value="All Stations">All Stations</option>
                                            {states && states.states.map((state) => (
                                                <option key={state} value={state}>{state}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div style={{ width: '55%' }}>
                                        <h6 className='text-primary mb-5 text-capitalize'>{per.query.title}'s {title} (Per Station in {per.state})</h6>
                                        <table className='table mb-5 border-black'>
                                            <thead className='text-primary'>
                                                <tr>
                                                    <th scope="col">Station</th>
                                                    <th scope="col" className='text-end text-capitalize'>{title}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pending ? <tr><td>Loading Data...</td></tr> : <>
                                                    {error ? <tr><td>{error}</td></tr> : <>
                                                        {data && data.map(({ _id, value }) => (
                                                            <tr key={_id}>
                                                                <td>{_id}</td>
                                                                <td className='text-end'>
                                                                    {title === 'revenue' &&
                                                                        <>
                                                                            <span className="naira">N</span>&nbsp;
                                                                            {value.toLocaleString()}
                                                                        </>
                                                                    }
                                                                    {title !== 'revenue' &&
                                                                        <>
                                                                            {value.toLocaleString() + ' KG'}
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>}
                                                </>}
                                            </tbody>
                                        </table>
                                        <div className="related-links mb-5">
                                            <Link to='#!' className="text-primary" onClick={() => changeStationQueryUrl('Today')}>
                                                <p className="d-flex">View Today's {title} (Per Station in {per.state}) <small className="d-block ms-2"><i className="bi bi-arrow-right-circle-fill"></i></small></p>
                                            </Link>
                                            <Link to='#!' className="text-primary" onClick={() => changeStationQueryUrl('Yesterday')}>
                                                <p className="d-flex">View Yesterday's {title} (Per Station in {per.state}) <small className="d-block ms-2"><i className="bi bi-arrow-right-circle-fill"></i></small></p>
                                            </Link>
                                            <Link to='#!' className="text-primary" onClick={() => changeStationQueryUrl('Last Week')}>
                                                <p className="d-flex text-capitalize">View Last Week's {title} (Per Station in {per.state}) <small className="d-block ms-2"><i className="bi bi-arrow-right-circle-fill"></i></small></p>
                                            </Link>
                                            <Link to='#!' className="text-primary" onClick={() => changeStationQueryUrl('Last Month')}>
                                                <p className="d-flex text-capitalize">View Last Month's {title} (Per Station in {per.state}) <small className="d-block ms-2"><i className="bi bi-arrow-right-circle-fill"></i></small></p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
