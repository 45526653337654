import { useFetch } from '../hooks/useFetch'
import { base_api } from './Utils'

export default function Stats({ title, sales, url }) {
    const { data, error, pending } = useFetch(`${base_api}/${url}`)
    return (
        <>
            <div className="col-lg-4">
                <div className="p-3 bg-white rounded">
                    <h6 className="text-primary fw-600 text-capitalize">{title}</h6>
                    <h3 className={`my-4 text-center ${error && 'text-muted'}`}>
                        {pending ? 'Loading data...' :
                            <>
                                {error ? error : <>
                                    {!sales && <><span className="naira">N</span>&nbsp;</>}
                                    {data && data.toLocaleString()}
                                    {sales && <>&nbsp;KG</>}
                                </>}
                            </>
                        }
                    </h3>
                </div>
            </div>
        </>
    )
}
