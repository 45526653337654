import { Chart, registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Pie } from 'react-chartjs-2'
import { useFetch } from '../hooks/useFetch'
import {base_api} from './Utils'


Chart.register(...registerables, ChartDataLabels)

export default function PieChart({ url }) {
    const { data, error, pending } = useFetch(`${base_api}/${url}`)
    const values = {
        labels: data && data.labels,
        datasets: [{
            data: data && data.values,
            backgroundColor: ["#FE4F4F", "#924FFE", "#4FBFFE", "#54CC41"]
        }]
    }
    const options = {
        plugins: {
            legend: {
                labels: {
                    usePointStyle: true,
                    boxWidth: 10,
                    position: 'bottom',
                    font: {
                        family: 'Poppins'
                    }
                }
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                      return  sum += data;
                    });
                    let percentage = Math.round((value * 100 / sum).toFixed(2)) + "%";
                    return percentage;
                },
                color: '#fff',
                font: {
                    family: 'Poppins'
                }
            }
        }
    }

    return (
        <>
            {pending ? 'Loading Data...' : <>
                {error ? error : <Pie data={values} options={options} height={125} />}
            </>}
        </>
    )
}