import BarChart from "./BarChart"
import PieChart from "./PieChart"
import { Link } from "react-router-dom"

export default function Charts({ pieTitle, barTitle, reverse, more, revenue, url }) {
    return (
        <>
            <div className="charts my-4">
                <div className={`row ${reverse && 'flex-row-reverse'}`}>
                    <div className="col-lg-4">
                        <div className="p-3 bg-white rounded">
                            <h6 className="text-primary fw-600 mb-4">{pieTitle}</h6>
                            <PieChart url={url} />
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="p-3 bg-white rounded">
                            {more ?
                                <>
                                    <div className="d-flex">
                                        <h6 className="text-primary fw-600 mb-4">{barTitle}</h6>
                                        <Link to={revenue ? '/revenue/details' : '/sales/details'} className="d-block ms-auto">
                                            <h6 className="text-primary fw-600 d-flex">More <small className="d-block ms-1"><i className="bi bi-arrow-right-circle-fill"></i></small></h6>
                                        </Link>
                                    </div>
                                </> :
                                <h6 className="text-primary fw-600 mb-4">{barTitle}</h6>
                            }
                            <small className='d-block text-end fw-600'>*All points in multiples of 100</small>
                            <BarChart url={url} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
